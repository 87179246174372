const initialState = {
  metadata: null,
};

const featuredReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_FEATURED_REQUEST":
      return {
        ...state,
        loadingFeatured: true,
      };
    case "LOAD_FEATURED":
      return {
        ...state,
        loadingFeatured: false,
        metadata: action.payload.metadata,
      };
    default:
      return state;
  }
};

export default featuredReducer;
