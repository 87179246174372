import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';


function Navigation() {
  return (
<Navbar bg="none" expand="lg" variant="dark">
  <Container>
    <Navbar.Brand href="#home">
      <img src="assets/images/logo.png" alt="Planetoid.me logo"/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="https://twitter.com/planetoid_me" target="_blank"><i class="bi-twitter"></i></Nav.Link>
        <Nav.Link href="https://discord.gg/aBh8GEYF" target="_blank"><i class="bi-discord"></i></Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

  );
}

export default Navigation;