import React from "react";
import Container from 'react-bootstrap/Container';

function Roadmap() {
  return (
    <Container id="roadmap" className="text-light">
      <h1>Roadmap</h1>
      <p>
        We are hard at work to provide more and more content to the Planetoid Universe. As the different collections sell out, we will be providing more and more features for you to discover.  Some of the features that are possible in the planetoid universe are listed below.  As with all our collections, a portion of the proceeds will go to STEAM based organizations that help kids learn about the universe.
      </p>
      <h3>Hi-Res Renders</h3>
      <p>As we upgrade our instrumentation at Planetoid.me, we will be able to provid you hi-res renderings of your planetoids.  When this feature is available, all owners will be able to purchase renderings.</p>
      <h3>Moons</h3>
      <p>Discover moons that orbit your planetoids.</p>
      <h3>Suns</h3>
      <p>Discover the sun or suns that your planetoids orbit.</p>
      <h3>Solar System</h3>
      <p>Combine all your planetoids, moons and suns into a solar system.</p>
      <h3>Spaceships</h3>
      <p>Build a spaceship that will let you visit your planetoids.</p>
    </Container>
  );
}

export default Roadmap;


