import React from "react";
import Container from 'react-bootstrap/Container';

function Donations() {
  return (
    <Container id="donations" className="text-light">
      <h1>Who we help</h1>
      <p>
        We are currently looking for organizations that we can help.  If you have any suggestions, join the Discord and send us a message.  A portion of all the proceeds from minting planetoids will go to our partners.
      </p>
    </Container>
  );
}

export default Donations;


