import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Trait from './Trait';

function Planetoid(props) {
  const traits = () => {
    var ret = [];
    ret.push(<Trait trait={{trait_type: "Planetoid Name", value: props.metadata.name}} />);

    for(var i=0; i<props.metadata.attributes.length; i++) {
      ret.push(<Trait trait={props.metadata.attributes[i]} />);
    }
    //var discovered={trait_type: "Discovered", value: props.metadata.date, type:"date"};
    //ret.push(<Trait trait={discovered} />);

    return ret;
  }
  const getDateStr = () => {
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric' };
    var dt = new Date(parseInt(props.metadata.date) * 1000);
    return dt.toLocaleDateString("en-US", options);
  }

  if(props.sm === true) {
    return (
      <Container className="sm-planetoid" onClick={(e) => { props.click(props.metadata)}}>
        <Row className="align-items-center">
          <Col md="auto">
            <img class="rnd-corners" src={props.metadata.actualimage.replace("ipfs://", "https://ipfs.io/ipfs/")} alt={"Planetoid" + props.metadata.name}/> 
          </Col>
          <Col md="auto">
            <h5 class="planetoidTitle">{props.metadata.name}</h5>
            <p class="click-details">Click for Details</p>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
          <Card bg="dark" style={{display: "inline-block", padding: 10, margin: 10}}>
            <Card.Body>
              <Row>
                <Col md="auto">
                  <img class="rnd-corners" src={props.metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/")} alt={"Planetoid" + props.metadata.name}/>
                  <div class="create-date">{props.metadata.name} (4x magnification)</div>
                </Col>
                <Col>
                  <h5 class="planetoidTitleLg"><img class="rnd-corners" src={props.metadata.actualimage.replace("ipfs://", "https://ipfs.io/ipfs/")} alt={props.metadata.name}/> {props.metadata.name}</h5>
                  <div class="datasheet">Datasheet</div>
                    {traits()}
                </Col>
              </Row>
            </Card.Body>
          </Card>        
    );
  }

  
}

export default Planetoid;