import React from "react";
import Container from 'react-bootstrap/Container';

function Contact() {
  return (
    <Container id="roadmap" className="text-light">
      <h1>Contact Planetoid.me</h1>
      <p>
        You can contact us on Twitter or through our Discord:
        <p><a href="https://twitter.com/planetoid_me" target="_blank" style={{color:'white', fontSize: 30, paddingLeft: 30, paddingRight: 30}}><i class="bi-twitter"></i></a>
        <a href="https://discord.gg/aBh8GEYF" target="_blank"  style={{color:'white', fontSize: 30}}><i class="bi-discord"></i></a></p>
      </p>
    </Container>
  );
}

export default Contact;
