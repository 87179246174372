// log
import store from "../store";
import * as apiService from "../../services/apiService";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};


const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};


export const fetchData = (account) => {
  console.log("Fetching Data");
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let name = await store
        .getState()
        .blockchain.smartContract.methods.name()
        .call();
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.limit()
        .call();
      let minted = await store
        .getState()
        .blockchain.smartContract.methods.minted()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();
      let myPlanets = await store
        .getState()
        .blockchain.smartContract.methods.getOwnerPlanetoids(store.getState().blockchain.account)
        .call();

      dispatch(
        fetchDataSuccess({
          name,
          totalSupply,
          minted,
          cost,
          myPlanets
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
