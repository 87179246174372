import React from "react";
import Container from 'react-bootstrap/Container';
import {
  Footer,
  Collections,
  Roadmap,
  Donations,
  Contact
} from ".";

function Home() {
  return (
    <Container id="home" className="text-light">
      <div class="clearfix">
        <h1><img class="rnd-corners" src="assets/images/planetoids/17.png" alt="" style={{paddingRight: 10}}/>Welcome to Planetoid.me<img class="rnd-corners" src="assets/images/planetoids/23.png" alt="" style={{paddingLeft: 10}}/></h1>
        <p>
          <img class="rnd-corners img-fluid" align="right" src="assets/images/lab.png" alt="Planetoid Me Lab" style={{width: "30%", padding: 10}}/>

          Here at Planetoid.me, we have been striving to further human knowledge as we look to the stars.  We search for planetoids outside our solar system. The planetoids we’ve found so far have been magnificent.  We categorize the planetoids into seven different categories that have many different traits.  Surface features, rings and atmosphere are just a few of them.  We are always looking for signs of life.  We estimate that there are at least 10,000 planetoids to be found. 
          Keep scrolling to find your own planetoid.
        </p>

        <p>
          With all of our collections, a portion of the proceeds will go to a STEAM based organization that helps kids learn about the universe.
        </p>
      </div>
      <Collections />
      <Contact />
      <Roadmap />
      <Donations />
      <Footer />

    </Container>
  );
}

export default Home;


