import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
//import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Home
} from "./components";
import store from "./redux/store";
import { Provider } from "react-redux";

ReactDOM.render(
	<Provider store={store}>
  <Router>
    <Navigation />
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>
  </Provider>,

  document.getElementById("root")
);

//serviceWorker.unregister();


//import 'bootstrap/dist/css/bootstrap.css';
//import React from "react";
//import ReactDOM from "react-dom";
//import App from "./App";
//import reportWebVitals from "./reportWebVitals";
//import store from "./redux/store";
//import { Provider } from "react-redux";
//import "./styles/reset.css";
//import "./styles/theme.css";

//ReactDOM.render(
//  <Provider store={store}>
//    <App />
//  </Provider>,
//  document.getElementById("root")
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
