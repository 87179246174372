import React from 'react';

function Trait(props) {
  const getValueStr = () => {
    if(props.trait.type && props.trait.type === "date") {
      var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour:'numeric', minute:'numeric' };
      var dt = new Date(parseInt(props.trait.value) * 1000);
      return dt.toLocaleDateString("en-US", options);
    } else {
      return props.trait.value;
    }
  }

  return (
      <div class="trait-container">
        <div class="trait-type">{props.trait.trait_type}</div>
        <hr/>
        <div class="trait-value">{getValueStr()}</div>
      </div>
  );
}

export default Trait;