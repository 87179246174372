// log
import * as apiService from "../../services/apiService";

const loadFeaturedRequest = () => {
  return {
    type: "LOAD_FEATURED_REQUEST",
  };
};

const loadFeaturedSuccess = (payload) => {
  return {
    type: "LOAD_FEATURED",
    payload: payload,
  };
};

const loadFeaturedFailed = (payload) => {
  return {
    type: "LOAD_FEATURED_FAILED",
    payload: payload,
  };
};

export const loadFeatured = (account) => {
  return async (dispatch) => {
    dispatch(loadFeaturedRequest());
    try {
        var metadata = [];
        var proxy = "https://gateway.pinata.cloud/"; // https://ipfs.io/
        var planetoid1 = await apiService.getMetadata(proxy + "ipfs/QmZQaMjGC4abXdENcMHD563hXWD3f5ArdjcQ8dM9Qfszss");
        var planetoid2 = await apiService.getMetadata(proxy + "ipfs/Qmeg2JjpAsJDERdViqw7K9HuoAHugHoDRoXcb5QCCUpAJ6");
        var planetoid3 = await apiService.getMetadata(proxy + "ipfs/QmVPQWiKFieUCx6pUNPevWsAAkMPAWPqkizGKGLig4oF7z");
        metadata.push(planetoid1);
        metadata.push(planetoid2);
        metadata.push(planetoid3);
        console.log("FOUND FEATURED", metadata);
      dispatch(
        loadFeaturedSuccess({
          metadata
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(loadFeaturedFailed("Could not load featured."));
    }
  };
};
