import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import { loadFeatured } from "../redux/featured/featuredActions";
import * as apiService from "../services/apiService";
import Planetoid from "./Planetoid";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Spinner from 'react-bootstrap/Spinner';
import Web3 from "web3";

function Collections() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const web3 = useSelector((state) => state.web3);
  const data = useSelector((state) => state.data);
  const featured = useSelector((state) => state.featured);
  const [feedback, setFeedback] = useState({"msg":"","status":""});
  const [claimingNft, setClaimingNft] = useState(false);
  const [show, setShow] = useState(false);
  const [currentPlanetoid, setCurrentPlanetoid] = useState(false);
  const [myPlanetoids, setMyPlanetoids] = useState(false);
  const [loadingMyPlanetoids, setLoadingMyPlanetoids] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [mintingButton, setMintingButton] = useState(false);
  const [mintCount, setMintCount] = useState(1);


  const claimNFTs = async (_amount) => {
    if (_amount <= 0) {
      return;
    }
    if(mintCount > 1) {
      setMintingButton("MINTING " + mintCount + " PLANETOIDS");
      setFeedback({"msg": "Minting your Planetoids...", "status":"message"});
    } else {
      setMintingButton("MINTING PLANETOID");
      setFeedback({"msg": "Minting your Planetoid...", "status":"message"});    
    }
    setClaimingNft(true);
    var hashes = await apiService.getHashes(blockchain.account, mintCount);
    if(hashes.error) {
      if(hashes.error.error === "100003") {
          setFeedback({"msg": "There are no available planetoids at this time.  Either the minting timeframe has elapsed or all planetoids have been reserved.  Please check again in the future or view the whole collection on OpenSea.", "status":"error"});
      } else {
        setFeedback({"msg": hashes.error.errorMessage, "status":"error"});
      }
      setClaimingNft(false);
    } else {

      console.log(hashes.hashes);
      if(hashes.hashes.length != mintCount) {
        setClaimingNft(false);
        setMintCount(hashes.hashes.length);
        setFeedback({"msg": "There are only " + hashes.hashes.length + " Planetoids left.  Would you like to mint them? Push the button above to mint "    + hashes.hashes.length + " Planetoids", "status":"message"});
        return;
      }

      try{
        console.log("SENDING::");
        console.log("SENDING::", data.cost * mintCount);
        //const resGasMethod = await blockchain.smartContract.methods.mintPlanetoids(blockchain.account, hashes.hashes).estimateGas();
        var receipt = await blockchain.smartContract.methods.mintPlanetoids(blockchain.account, hashes.hashes)
          .send({
            //gasLimit: "" + resGasMethod,
            from: blockchain.account,
            value: "" + (data.cost * mintCount)
          });


          console.log("MINT RECEIPT", receipt);
          setFeedback({"msg":
            "Success!!  Please allow up to 5 min for the transaction to complete.  Your newly discovered planetoid will appear in the 'My Collection' section.  If it doesn't appear, refresh the page.","status":"success"
          }
          );
          if(mintCount > 1) {
            setMintingButton("CLAIMING PLANETOIDS");
          } else {
            setMintingButton("CLAIMING PLANETOID");
          }
          var processStatus = await apiService.processEvents();
          if(processStatus.error) {
            setFeedback(
              {"msg":"Your planetoid was minted and will be available shortly.","status":"success"}
            );          
          }
          if(mintCount > 1) {
            setFeedback({"msg":
              "Success!!  Your " + mintCount + " newly discovered planetoids will appear in the 'My Collection' section.  If it doesn't appear, refresh the page.","status":"success"
            }
            );          
          } else {
            setFeedback({"msg":
              "Success!!  Your newly discovered planetoid will appear in the 'My Collection' section.  If it doesn't appear, refresh the page.",
              "status":"success"}
            );          
          }
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
      } catch(e) {
          console.log("OBJ::::", e.message);
          if(e.message.indexOf("This token was already minted")) {
            setFeedback({"msg":
              "There was an error minting your planetoid.  The token you tried to mint was already minted. Please try again.",
              "status":"error"
            });

          } else {
            setFeedback({"msg":
                "There was an error minting your planetoid.  Did you send enough funds?",
                "status":"error"
            });
          }
          setClaimingNft(false);        
      }
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    console.log("LOad Data Effect");
    getData();
    loadMetadata();
  }, [blockchain.account, loadData]);

  useEffect(() => {
    dispatch(loadFeatured());
  }, []);


  const loadMetadata = async function () {
    if(!data.myPlanetoids) return;
    setLoadingMyPlanetoids(true);
    var metadataList = [];
    for(var i=0; i<data.myPlanetoids.length; i++) {
      var metadata = await apiService.getMetadata(data.myPlanetoids[i]);
      if(metadata.error) continue;
      var metadataItem = {};
      metadataItem.metadata = metadata;
      metadataItem.hash = data.myPlanetoids[i];
      metadataList.push(metadataItem);
    }
    setMyPlanetoids(metadataList);
    setLoadingMyPlanetoids(false);
  }

  useEffect(() => {

    if(!data || !data.myPlanetoids) {
      setLoadingMyPlanetoids(false);
      return;
    }
    loadMetadata();

  }, [data.myPlanetoids]);

  const showModal = (planetoid) => {
    console.log(planetoid);
    setCurrentPlanetoid(planetoid);
    setShow(true);
  }

  const updateData = () => {
    setLoadData(!loadData);
    setLoadingMyPlanetoids(true);
    console.log("Update Data");
  }

  const formatMine = () => {
    var ret = [];
    for(var i=0; i<myPlanetoids.length; i+=3) {
      ret.push(<Row>
        <Col xs={12} lg={4}><Planetoid sm={true} metadata={myPlanetoids[i].metadata} click={showModal} /></Col>
        {(i+1) < myPlanetoids.length ? (
          <Col xs={12} lg={4}><Planetoid sm={true} metadata={myPlanetoids[i+1].metadata} click={showModal} /></Col>
          ) : (null)}
        {(i+2) < myPlanetoids.length ? (
          <Col xs={12} lg={4}><Planetoid sm={true} metadata={myPlanetoids[i+2].metadata} click={showModal} /></Col>
        ) : (null)}
      </Row>);
    }
    return ret;
  }

  const formatFeatured = () => {
    var ret = [];
    for(var i=0; i<featured.metadata.length; i+=3) {
      ret.push(<Row>
        <Col xs={12} lg={4}><Planetoid sm={true} metadata={featured.metadata[i]} click={showModal} /></Col>
        {(i+1) < featured.metadata.length ? (
          <Col xs={12} lg={4}><Planetoid sm={true} metadata={featured.metadata[i+1]} click={showModal} /></Col>
          ) : (null)}
        {(i+2) < featured.metadata.length ? (
          <Col xs={12} lg={4}><Planetoid sm={true} metadata={featured.metadata[i+2]} click={showModal} /></Col>
        ) : (null)}
      </Row>);
    }
    return ret;
  }

  return (
      <>
      { featured && featured.metadata && featured.metadata.length > 0 ? (
      <Container id="featured">
        <h1>Featured Planetoids</h1>
        <Container>
          {formatFeatured()}
        </Container>
      </Container>
      ) : (null) }
      {myPlanetoids && myPlanetoids.length > 0 ? (
        <Container  id="myplanetoids" >
          <h1>My Collection</h1>
          <Container>
            {formatMine()}
          </Container>
          <Container className="d-flex flex-row-reverse">
             <Button onClick={() => {updateData();}}><i class="bi-arrow-clockwise"></i> Reload</Button>
          </Container>
        </Container>
      ) : ( 
        <Container  id="myplanetoids" >
          <h1>My Collection</h1>
          {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <>
                  <Card id="mint-one">
                    <Card.Body>
                      <Card.Title>View your Collection</Card.Title>
                      <Card.Text>
                        To view your collection, Connect to the Polygon network.

                       {blockchain.errorMsg !== "" ? (
                          <p className="error">
                              {blockchain.errorMsg}
                          </p>
                        ) : null}

                      </Card.Text>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                      Connect to MetaMask
                    </Button>
                    </Card.Body>
                  </Card>
                  </>
                ) : (
                  <>
                  {loadingMyPlanetoids ? (
                    <Stack>
                      <p style={{display: 'flex', justifyContent: 'center'}}><Spinner animation="border" /></p>
                      <p style={{display: 'flex', justifyContent: 'center'}}>Loading your Planetoids</p>
                    </Stack>
                  ) : (
                    <>
                    <p>
                      You have not minted any planetoids yet.  Look for the Mint button below to get started.
                    </p>
                      <Container className="d-flex flex-row-reverse">
                       <Button onClick={() => {updateData();}}><i class="bi-arrow-clockwise"></i> Reload</Button>
                      </Container>
                    </>
                  )}
                  </>
                )
          }

        </Container>
      )}
      <Container id="collections">
        <h1>Now Minting</h1>
        <Container>
          <p>
              We are now minting the first edition of Planetoids.  There are about 10,000 planetoids to be discovered. Each planetoid is unique and has 7 different categoies of attributes.
          </p>
          <Row>
            <Col xs={12} lg={6}>
               <img src="assets/images/planetoids/1.png" alt="Planetoid 1" />
               <img src="assets/images/planetoids/2.png" alt="Planetoid 2" />
               <img src="assets/images/planetoids/3.png" alt="Planetoid 3" />
               <img src="assets/images/planetoids/4.png" alt="Planetoid 4" />
               <img src="assets/images/planetoids/5.png" alt="Planetoid 5" />
               <img src="assets/images/planetoids/6.png" alt="Planetoid 6" />
               <img src="assets/images/planetoids/7.png" alt="Planetoid 7" />
               <img src="assets/images/planetoids/8.png" alt="Planetoid 8" />
               <img src="assets/images/planetoids/9.png" alt="Planetoid 9" />
               <img src="assets/images/planetoids/10.png" alt="Planetoid 10" />
               <img src="assets/images/planetoids/11.png" alt="Planetoid 11" />
               <img src="assets/images/planetoids/12.png" alt="Planetoid 12" />
               <img src="assets/images/planetoids/13.png" alt="Planetoid 13" />
               <img src="assets/images/planetoids/14.png" alt="Planetoid 14" />
            </Col>
            <Col xs={12} lg={6}>


              {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <Card id="mint-one">
                    <Card.Body>
                      <Card.Title>Mint a Planetoid</Card.Title>
                      <Card.Text>
                        To get started, Connect to the Polygon network.

                       {blockchain.errorMsg !== "" ? (
                          <p className="error">
                              {blockchain.errorMsg}
                          </p>
                        ) : null}

                      </Card.Text>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                      Connect to MetaMask
                    </Button>
                    </Card.Body>
                  </Card>
                ) : (
                  <Card id="mint-one">
                    <Card.Body>
                      <Card.Title>Mint a Planetoid</Card.Title>
                      <Card.Text>
                        Mint a planetoid for {
                          Web3 && Web3.utils ? Web3.utils.fromWei("" + data.cost, 'ether')
                          : "NA" } MATIC<br/>
                      </Card.Text>
                      <Form.Label>How many planetoids would you like to mint?</Form.Label>
                      <Form.Select style={{width: "50%"}}onChange={(value) => { console.log(value.target.value); setMintCount(value.target.value);} }>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </Form.Select>

                      <Button
                      style={{marginTop:10}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs(1);
                        }}
                      >
                        {claimingNft ? (
                        <><Spinner animation="border" size="sm" /> {mintingButton}</> ) : "MINT " +  mintCount + " PLANETOID" + (mintCount > 1 ? "S" : "")}
                      </Button>

                        <p style={{marginTop: 10}} className={feedback.status} >{feedback.msg}</p>

                    </Card.Body>
                  </Card>
                )}
              </Col>
            </Row>
        </Container>
      </Container>

<a href="https://testnets.opensea.io/collection/planetoidme" style={{textDecoration: "none"}}target="_blank">
      <Container  id="opensea" onClick={(e) => { }}>
        <h1>OpenSea Collection</h1>
          <Container>
          <Row xs={1} md={2} lg={3} className="justify-content-center">
            <Col style={{ flex: 0, marginLeft: 0, marginRight:0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_1.png" alt="Planetoid Row" /></Col>
            <Col style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_2.png" alt="Planetoid Row"/></Col>
            <Col className="d-none d-lg-block" style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_3.png" alt="Planetoid Row"/></Col>
          </Row>
          <Row xs={1} md={2} lg={3}  className="justify-content-center">
            <Col style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_4.png" alt="Planetoid Row"/></Col>
            <Col style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}>
              <div className="d-flex justify-content-center align-items-center border text-light" style={{width: 256, height: 64}}>
                OpenSea Planetoid Collection
              </div>
            </Col>
            <Col className="d-none d-lg-block" style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_5.png" alt="Planetoid Row"/></Col>
          </Row>
          <Row xs={1} md={2} lg={3} className="justify-content-center">
            <Col style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_7.png" alt="Planetoid Row"/></Col>
            <Col style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_8.png" alt="Planetoid Row"/></Col>
            <Col className="d-none d-lg-block" style={{ flex: 0, paddingLeft: 0, paddingRight: 0, width: 256 }}><img src="assets/images/4x1_9.png" alt="Planetoid Row"/></Col>
          </Row>
        </Container>
      </Container>
</a>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}> 
        <Modal.Header>
          <Modal.Title>Planetoid Details</Modal.Title>
          <CloseButton variant="white" onClick={(e) => { setShow(false); }} />
        </Modal.Header>
        <Modal.Body>
          {currentPlanetoid ? (
            <Container  id="myplanetoids" >
              
              <Stack direction="horizontal" gap={3}>
                <Planetoid metadata={currentPlanetoid} />
              </Stack>
            </Container>
            ): (<h1>No Featured</h1>)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Collections;