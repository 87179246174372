export async function getHashes(accountId, num) {
	try {
		var url = process.env.REACT_APP_API_SERVER + "/api/generate/" + accountId + "/" + num;

	    let response = await fetch(url);
	    let dataObj = await response.json();

	    if(dataObj.error) {
	    	return {"error":dataObj};
	    } else {
	    	return dataObj;
	    }
  	} catch (error) {
    	return {"error": {
    				"error":200001,
    				"errorMessage":"Can't connect to the server.  Please try again later.",
    				"details":error
    			}
      };
  	}
}
export async function processEvents() {
  try {
    var url = process.env.REACT_APP_API_SERVER + "/api/processEvents";

    let response = await fetch(url);
    let dataObj = await response.json();

    if(dataObj.error) {
        return {"error":dataObj};
    } else {
        return dataObj;
    } 

  }catch (error) {
      return {"error": {
          "error":200003,
          "errorMessage":"HTTP Error",
          "details":error
      }
    };
  }  
}

export async function updateHashs(accountId, hashes) {
	try {
		var url = process.env.REACT_APP_API_SERVER + "/api/fulfill";

		let response = await fetch(url);
	  let dataObj = await response.json();

	    if(dataObj.error) {
	    	return {"error":dataObj};
	    } else {
	    	return dataObj;
	    }
  	} catch (error) {
    	return {"error": {
    				"error":200002,
    				"errorMessage":"HTTP Error",
    				"details":error
    			}
      };
  	}
}

export async function getMetadata(url, options = {}) {
  try {
    const { timeout = 8000 } = options;
    var proxy = "https://gateway.pinata.cloud/"; // https://ipfs.io/

    var realUrl = url.replace("ipfs://", proxy + "ipfs/");
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(realUrl, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);

    let dataObj = await response.json();
      if(dataObj.error) {
        return {"error":dataObj};
      } else {
        return dataObj;
      }
    } catch (error) {
      return {"error": {
            "error":200002,
            "errorMessage":"HTTP Error",
            "details":error
          }
      };
    }
}
