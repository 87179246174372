import React from "react";
import Container from 'react-bootstrap/Container';

function Footer() {
  return (
    <Container id="footer">
            Copyright &copy; PlanetoidMe 2022
    </Container>
  );
}

export default Footer;